import { Stack } from "@mui/material"
import { CustomSwitch } from "../playerpage/CustomSwitch"

export const OmakaseDeleteSwitch = ({ isDeleteMode, setIsDeleteMode }) => {
  const handleChange = (event) => {
    const isChecked = event.target.checked;
    setIsDeleteMode(isChecked);
  };
  return (
    <div style={{ margin: "5px" }}>
      <Stack direction="row" spacing={1} sx={{ alignItems: "center", justifyContent: "end" }}>
        {isDeleteMode && <p style={{ fontSize: "10px", fontWeight: "bold", textAlign: "end", width: "70px" }}>リセット</p>}
        <CustomSwitch defaultChecked={false} onChange={handleChange} />
        <p style={{ fontSize: "10px", fontWeight: "bold", textAlign: "start", width: "70px" }}>重複なし</p>
      </Stack>
    </div>
  )
}