import { CharacterImg } from "../home/CharacterImg"

export const OmakaseFiveResult = ({ omakaseFiveCharacters, selectedValue, omakaseDate, evalRank }) => {
  return (
    <div id='omakase-five-result' style={{ width: "100%" }}>
      <div style={{ display: "flex", margin: "10px auto", width: "90%", maxWidth: "400px", minHeight: "40px", justifyContent: "space-around", alignItems: "end" }}>
        {omakaseFiveCharacters.map((omakaseFiveCharacter, index) => {
          return <OmakaseFiveResultCharacter index={index} omakaseCharacter={omakaseFiveCharacter} />
        })
        }
      </div>

      <div style={{ fontWeight: "bold", textAlign: "center" }}>
        <p style={{ marginBottom: "10px" }}>
        {selectedValue.isTop ? "上位 ": "下位 "}<span style={{ fontSize: "28px" }}>{selectedValue.sliderValue}</span>体からピック <span style={{ fontSize: "28px" }}>{evalRank}</span>ランクチーム
        </p>
        <p style={{ marginLeft: "5px", fontSize: "12px" }}>
          {omakaseDate}
        </p>
      </div>
    </div>
  )
}

const OmakaseFiveResultCharacter = ({ index, omakaseCharacter }) => {
  return (
    <div style={{ height: "150px", display: "flex", flexDirection: "column", justifyContent: index % 2 === 0 ? "start" : "end" }}>
      <p style={{ fontWeight: "bold", fontSize: "12px", textAlign: "center" }}>
        <span style={{ fontSize: "16px" }}>{omakaseCharacter.rank}</span>ランク
      </p>
      <CharacterImg characterName={omakaseCharacter.characterName} />
    </div>
  )
}