import { Stack } from "@mui/material";
import { CustomSwitch } from "../playerpage/CustomSwitch";

export const OmakaseTopBottomSwitch = ({ setIsTop }) => {
  const handleChange = (event) => {
    const isChecked = event.target.checked;
    setIsTop(!isChecked)

  };

  return (
    <div style={{ margin: "5px" }}>
      <Stack direction="row" spacing={1} sx={{ alignItems: "center", justifyContent: "end" }}>
        <p style={{ fontSize: "10px", fontWeight: "bold", width: "70px", textAlign: "end" }}>上位</p>
        <CustomSwitch defaultChecked={false} onChange={handleChange}/>
        <p style={{ fontSize: "10px", fontWeight: "bold", width: "70px", textAlign: "start" }}>下位</p>
      </Stack>
    </div>
  )
}