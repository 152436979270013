import axios from "axios";
import { getAuth } from "firebase/auth";

const baseURL = process.env.REACT_APP_API_BASE_URL;

export const axiosInstance = axios.create({
  baseURL: baseURL + "/api"
})

axiosInstance.interceptors.request.use(
  async (config) => {
    if (config.method === 'post') {
      const token = localStorage.getItem('token');
      const currentTime = Date.now();

      if (token && currentTime < getTokenExpiration(token)) {
        config.headers['Authorization'] = `Bearer ${token}`;
      } else {
        // トークンが存在しないか、有効期限切れの場合は、新しいトークンを取得
        const newToken = await getAuth().currentUser.getIdToken(true);
        localStorage.setItem('token', newToken)
        config.headers['Authorization'] = `Bearer ${newToken}`
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// トークンの有効期限を取得
const getTokenExpiration = (token) => {
  // トークンを分割してペイロード部分を取得
  const payloadBase64 = token.split('.')[1];
  const decodedPayload = JSON.parse(atob(payloadBase64));

  // exp フィールドを取得（秒単位のタイムスタンプ）
  return decodedPayload.exp * 1000; // ミリ秒に変換
};