import { Stack } from "@mui/material";
import { CustomSwitch } from "../playerpage/CustomSwitch";

export const OmakaseFiveSwitch = ({ setIsOmakaseFive, setSliderValue, disabled }) => {
  const handleChange = (event) => {
    const isChecked = event.target.checked;
    setIsOmakaseFive(isChecked);
    setSliderValue((value) => {
      return (isChecked && value < 5) ? 5 : value
    })
  };

  return (
    <div style={{ margin: "5px" }}>
      <Stack direction="row" spacing={1} sx={{ alignItems: "center", justifyContent: "end" }}>
        <p style={{ fontSize: "10px", fontWeight: "bold", width: "70px", textAlign: "end" }}>通常ver.</p>
        <CustomSwitch defaultChecked={false} onChange={handleChange} disabled={disabled} />
        <p style={{ fontSize: "10px", fontWeight: "bold", width: "70px", textAlign: "start" }}>おま５ver.</p>
      </Stack>
    </div>
  )
}